<script setup>
  const props = defineProps({
    larger: {
      type: Boolean,
      required: false,
      default: false,
    },
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'span',
    },
    gray: {
      type: Boolean,
      default: false,
    },
  });
</script>
<template>
  <component :is="props.type" :class="{'section-title--gray': gray, large, larger}" class="section-title">
    <slot />
  </component>
</template>
<style lang="scss" scoped>
  .section-title {
    position: relative;
    font-weight: var(--font-weight-700);
    display: inline-block;
    z-index: 1;
    font-size: 1.5rem;
    line-height: 1.5;
    width: fit-content;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-image: linear-gradient(90deg, var(--color-primary-blue) 50%, var(--color-primary-red) 50%, var(--color-primary-red) 100%);
    }
  }

  @media (max-width: 992px) {
    .section-title {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
</style>
