<script setup>
  import { useSession, route } from '@plenny/visitor';
  import { ref } from 'vue';

  const session = useSession();
  const mouseOver = ref('');

</script>
<template>
  <div @mouseover="mouseOver = true" @mouseleave="mouseOver = false" class="action-item">
    <VisitorLink :href="session.is_authenticated ? route('web.account.show') : 'javascript:void(0)'" class="link">
      <IconPersonCircle />
      <span class="text">{{ $t('Konto') }}</span>
    </VisitorLink>
    <Transition name="show">
      <HeaderAccountDetailsMini v-if="mouseOver && !session.is_authenticated" />
    </Transition>
  </div>
</template>
<style scoped lang="scss">
  .show-enter-from,
  .show-leave-to {
    opacity: 0;
  }

  .show-enter-active,
  .show-leave-active {
    transition: var(--transition-primary-popups);
  }

  .action-item {
    position: relative;
    height: 100%;

    &.scroll {
      .link {
        column-gap: 0;

        .text {
          font-size: 0;
          opacity: 0;
        }
      }
    }

    .link {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.375rem;
      font-size: 1.5rem;
      height: 100%;
      color: var(--color-main-black);

      &:hover {
        color: var(--color-primary-blue);
      }

      .text {
        font-size: 0.75rem;

        @media(max-width: 992px) {
          display: none;
        }
      }
    }

    .link,
    .text {
      transition-property: color, font-size, opacity;
      transition-duration: var(--transition-primary-duration);
      transition-timing-function: var(--transition-primary-timing-function);
    }
  }
</style>
