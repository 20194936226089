<script setup>
  import { useBreakpoints } from '$storefront';

  const breakpoints = useBreakpoints();

  const props = defineProps({
    attributes: Array,
    variants: Array,
    matrix: Object,
    breadcrumbs: Array,
    module: Object,
    sections: Array,
  });
</script>
<template>
  <main class="container">
    <SfBreadcrumbs :breadcrumbs="breadcrumbs" />
    <SectionRenderer :sections="sections" placement="before" />
    <div class="wrapper comparator-wrapper">
      <div class="page-title">
        <SegmentHeading larger type="h1">
          {{ module.title }}
        </SegmentHeading>
        <div v-if="module.description_short" class="comparator-description" v-html="module.description_short" />
      </div>
      <SfComparator v-bind="{attributes, variants, matrix}" :stickyDistance="breakpoints.lg ? 90 : 50" />
    </div>
    <SectionRenderer :sections="sections" placement="after" />
  </main>
</template>
<style lang="scss" scoped>
  main {
    margin-bottom: 60px;
  }

  .comparator-wrapper {
    background-color: var(--color-white);
    border-radius: 0.25rem;
    padding: 30px;
    margin: 2rem 0;

    .page-title {
      text-align: center;
      margin-bottom: 40px;
    }

    .comparator-description {
      margin: 1rem 0;
    }
  }

  @media(max-width: 992px) {
    .comparator-wrapper {
      padding: 1.5rem 0;

      .page-title {
        margin-bottom: 1rem;
      }
    }
  }
</style>
