<script setup>
  import { ref, onMounted } from 'vue';
  import { useShared } from '@plenny/visitor';

  const show = ref(false);
  const shared = useShared();
  const categories = ref([]);

  onMounted(() => {
    categories.value = shared.value.categories;
  });

  const showCategories = function() {
    show.value = true;
  };

  const hideCategories = function() {
    show.value = false;
  };
</script>
<template>
  <div class="cat">
    <div class="cat-wrapper">
      <ul>
        <li class="nav-item level-0 " v-for="item in categories" @mouseover="showCategories" @mouseleave="hideCategories">
          <VisitorLink :href="item.url" :rel="item.rel" :target="item.target" class="category">
            {{ item.name }}
            <IconChevronRight v-if="item.children.length > 0" />
          </VisitorLink>
          <div class="children-wrapper" v-if="item.children.length > 0 && show">
            <ul class="children">
              <HeaderNavItem v-for="item in item.children" :item="item" :depth="1" />
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<style scoped lang="scss">
  .cat {
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1600px;
    z-index: 9;
    overflow-y: hidden;
    transition: box-shadow 0.2s ease;

    &:hover {
      box-shadow: var(--box-shadow-menu);
    }

    .cat-wrapper {
      height: 100%;

      > ul {
        height: 100%;
        list-style: none;
        background-color: var(--color-white);
        border-bottom-left-radius: 5px;
        transition: box-shadow 0.2s ease;
        padding-top: 10px;
        overflow-y: auto;
        box-shadow: var(--box-shadow-menu);
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
          display: none;
        }

        &:hover {
          box-shadow: var(--box-shadow-menu);
        }

        & a.category {
          padding: 11px 0 11px 20px
        }
      }
    }
  }

  .nav-item {
    pointer-events: auto;

    &:hover {
      &.level-0 > .children-wrapper {
        display: block;
      }
    }

    &.level-2 {
      font-size: 15px;

      .children-wrapper {
        display: none;
      }
    }

    &.level-0 {

      > .children-wrapper {
        position: absolute;
        display: none;
        top: 0;
        left: 300px;
        right: 0;
        height: 100%;
        padding: 15px 15px 15px 50px;
        background-color: var(--color-white);
        box-shadow: 5px 5px 8px #00000015;
        border-bottom-right-radius: 5px;

        > .children {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: auto;
          gap: 32px 16px;
          max-height: 100%;
          margin: 0;
          overflow-y: auto;
          list-style: none;

          &::-webkit-scrollbar {
            width: 8px;
          }

          &::-webkit-scrollbar-track {
            background-color: var(--color-light-background);
            border-radius: 4px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: var(--color-primary-blue);
            border-radius: 4px;
          }

          @media(max-width: 1650px) {
            grid-template-columns: repeat(3, 1fr);
          }

          & > li > a {
            font-weight: 600;
            font-size: 15px
          }
        }
      }

      > a {
        position: relative;

        &.category {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          font-weight: 600;
          padding: 10px 0;
        }

        & svg {
          font-size: 9px;
          transition: var(--transition-primary);
          transform: translateX(-8px);
        }
      }

      &:hover {
        > a.category {
          color: var(--color-primary-blue);

          > svg {
            transform: translateX(0);
          }
        }
      }
    }

    a {
      display: block;
      padding: 0.25rem 0;
      color: var(--color-primary-black);
      transition: var(--transition-primary);

      &:hover,
      &.active {
        color: var(--color-primary-blue);
      }
    }

    ul {
      list-style: none;
    }
  }
</style>
