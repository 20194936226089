<script lang="ts" setup>
  import { ref } from 'vue';
  import { useShared, route } from '@plenny/visitor';
  import { useBreakpoints, useScrollDetector } from '$storefront';

  const htmlHeaderBottom = ref();
  const scroll = ref(false);
  const showMenu = ref(false);

  const breakpoints = useBreakpoints();
  const shared = useShared();

  useScrollDetector({
    threshold: 8,
    callback: ({ up }) => {
      if (htmlHeaderBottom.value) {
        if (up) {
          htmlHeaderBottom.value.classList.add('header__bottom--open');
          scroll.value = false;
        } else {
          htmlHeaderBottom.value.classList.remove('header__bottom--open');
          scroll.value = true;
        }
      }
    },
  });
</script>
<template>
  <TopBarInfo v-if="breakpoints.md" id="header-top-bar-info" :aria-hidden="!breakpoints.md" />
  <header :class="{ 'header--scroll': scroll}" class="header">
    <span class="header__line"></span>
    <div class="header__top">
      <div class="container header__top__container">
        <div class="header__top__logo-wrapper">
          <VisitorLink :href="route('web.homepage.index')" class="header__top__logo" target="_self">
            <SfLogo class="header__top__logo__img" />
          </VisitorLink>
        </div>
        <HeaderSearch v-if="breakpoints.lg" :placeholder="$t('Wyszukaj produkt')" />
        <div class="header__top__icons">
          <HeaderFavorite v-if="breakpoints.lg" :class="{ scroll }" />
          <HeaderAccount v-if="breakpoints.lg" :class="{ scroll }" />
          <MiniCart :class="{ scroll }" />
          <IconHamburger v-if="!breakpoints.lg" class="icon-hamburger" @click="showMenu = true" />
        </div>
      </div>
    </div>
    <div ref="htmlHeaderBottom" class="header__bottom header__bottom--open">
      <div class="container header__bottom__inner">
        <div class="header__bottom__inner__wrapper">
          <div class="header__bottom__inner__wrapper__content">
            <HeaderSearch v-if="!breakpoints.lg" :placeholder="$t('Wyszukaj produkt')" />
            <Categories v-if="breakpoints.lg" compact />
            <nav v-if="breakpoints.lg" class="navigation">
              <ul>
                <HeaderNavItem v-for="item in shared.menu.menu_top" :depth="0" :item="item" />
              </ul>
            </nav>
          </div>
          <VatSwitcher v-if="breakpoints.lg" />
        </div>
      </div>
    </div>
  </header>
  <MenuDrawer v-if="!breakpoints.lg" v-model:open="showMenu" />
</template>
<style lang="scss" scoped>
  @keyframes show-overflow {
    from {
      overflow: hidden;
    }
    to {
      overflow: visible;
    }
  }

  .icon-hamburger {
    font-size: 1.5rem;
  }

  .header {
    position: sticky;
    top: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-primary);
    transition: var(--transition-header-scroll);

    &__line {
      display: none;
    }

    &__top {
      &__container {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        width: 98%;
        margin: 0 auto;
        height: 90px;
        align-items: center;
        column-gap: 20px;
        padding: 15px 0;
      }

      &__logo__img {
        max-width: 200px;
        height: 60px;
      }

      &__icons {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        justify-content: flex-end;
        column-gap: 40px;
        height: 100%;

        :deep(svg) {
          transition: var(--transition-primary);
        }
      }
    }

    &__bottom {
      &__inner {
        width: 98%;
        margin: 0 auto;
      }
    }

    &--scroll {
      .header__top {
        &__icons {
          column-gap: 25px;
        }
      }
    }
  }

  @media(max-width: 1400px) {
    .header {
      &__top {
        &__icons {
          column-gap: 20px;
        }
      }
    }
  }

  @media(max-width: 1200px) {
    .header {
      &__top {
        &__logo, &__icons {
          width: auto;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .header {
      box-shadow: var(--box-shadow-primary);

      &__top {
        &__container {
          display: flex;
          gap: 1.5rem;
          height: 50px;
          padding: 0.625rem;
        }

        &__logo {
          height: 100%;
          display: inline-block;

          &__img {
            width: 100px;
            height: 30px;
          }
        }

        &__logo-wrapper {
          height: 30px;
          margin-right: auto;
        }

        &__icons {
          width: auto;
          gap: 1.5rem;
          flex: 0 0 auto;
        }
      }

      &__bottom {
        .header__bottom__inner__wrapper {
          padding: 0 0.625rem 0.5rem;

          &__content {
            width: 100%;
          }
        }
      }

      &--scroll {
        .header__top {
          &__icons {
            column-gap: 1.5rem;
          }
        }
      }
    }
  }

  .header__bottom {
    display: grid;
    width: 100%;
    min-width: 0;
    grid-template-rows: 0fr;
    transition: grid-template-rows ease-in-out 100ms;
    border-top: 1px solid var(--color-medium-gray);

    @media(max-width: 992px) {
      border-top: none;
    }

    &--open {
      grid-template-rows: 1fr;

      .header__bottom__inner {
        animation: show-overflow ease-in-out 100ms;
        overflow: visible;
      }
    }

    &__inner {
      position: relative;
      display: flex;
      width: 98%;
      margin: 0 auto;
      min-width: 0;
      overflow: hidden;

      &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-width: 0;

        &__content {
          display: flex;
          align-items: center;
          flex: 0 0 auto;
        }

        @media(max-width: 992px) {
          align-items: center;
          gap: 20px;
          padding: 10px 15px;
        }
      }
    }
  }

  .navigation {
    position: relative;
    width: 100%;
    padding-left: 2rem;

    ul {
      display: flex;
      gap: 1rem;
      padding: 0;
      margin: 0;
      list-style: none;

      li.nav-item {
        position: relative;

        :deep(.nav-item-arrow) {
          transform: rotate(0);
          font-size: 0.75rem;
          margin-left: 0.5rem;
        }

        :deep(> .children-wrapper) {
          top: 100%;
          left: 0;
          right: unset;
          height: auto;
          padding: 0.5rem;
          min-width: 300px;
          border-radius: 0;

          a {
            font-weight: var(--font-weight-500);
            padding: 0.5rem 0;
          }

          .nav-item.level-2 .children-wrapper {
            display: flex;
            flex-direction: column;
          }

          .children {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            padding-left: 1rem;
          }

          @media(max-width: 1200px) {
            left: unset;
            right: 0;
          }
        }
      }
    }

    :deep(.level-0) {
      & > a {
        text-decoration: none;
        padding: 1rem 0.375rem;
        color: var(--color-main-black);
        font-weight: var(--font-weight-400);
        transition-duration: var(--transition-primary-duration);
        transition-timing-function: var(--transition-primary-timing-function);
        z-index: 2;

        &:not(.active):hover {
          color: var(--color-primary-blue);
        }

        &.active {
          position: relative;
          display: inline-block;
          font-weight: var(--font-weight-700);
          //
          //&:after {
          //  content: '';
          //  position: absolute;
          //  bottom: 0;
          //  right: 0;
          //  left: 0;
          //  height: 2px;
          //  background-color: var(--color-primary-red);
          //}
        }
      }
    }
  }

  @media (max-width: 992px) {
    .navigation {
      padding-left: 10px;

      ul {
        gap: 14px;
        font-size: 15px;
      }
    }
  }

  @media(max-width: 768px) {
    #header-top-bar-info {
      display: none;
    }
    .header__line {
      display: block;
      height: 3px;
      background-image: linear-gradient(90deg, var(--color-primary-blue) 25%, var(--color-primary-red) 25%, var(--color-primary-red) 50%, var(--color-primary-blue) 50%, var(--color-primary-blue) 75%, var(--color-primary-red) 75%);
    }
  }
</style>
