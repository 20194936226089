<script setup lang="ts">
  import { useShared } from '@plenny/visitor';

  const shared = useShared();

</script>
<template>
  <div class="top-bar">
    <span class="top-bar__line"></span>
    <div class="top-bar__content container">
      <div class="top-bar__contact">
        <a v-if="shared.department.email" :href="`mailto:${shared.department.email}`" class="top-bar__contact-item top-bar__contact-email">
          <IconEnvelope />
          <span v-html="shared.department.email" />
        </a>
        <a v-if="shared.department.phone" :href="`tel:${shared.department.phone}`" class="top-bar__contact-item top-bar__contact-phone">
          <IconPhone />
          <span v-html="shared.department.phone" />
        </a>
      </div>
      <span class="top-bar__text">
        {{ $t('Zapisz się do newslettera aby być na bieżąco') }}
      </span>
    </div>
  </div>
</template>
<style scoped lang="scss">
  .top-bar {
    position: relative;
    display: flex;
    flex-direction: column;
    background: black;
    width: 100%;
    z-index: 999;

    &__line {
      display: block;
      height: 3px;
      background-image: linear-gradient(90deg, var(--color-primary-blue) 25%, var(--color-primary-red) 25%, var(--color-primary-red) 50%, var(--color-primary-blue) 50%, var(--color-primary-blue) 75%, var(--color-primary-red) 75%);
    }

    &__content {
      display: flex;
      justify-content: space-between;
      font-size: 0.75rem;
      padding: 0.75rem 0 0.625rem;
    }

    &__contact {
      display: flex;
      gap: 2.5rem;
      line-height: 1.125rem;

      svg {
        font-size: 1rem;
      }
    }

    &__contact-item {
      display: flex;
      align-items: center;
      gap: 0.625rem;
      color: white;
      transition-property: color;
      transition-duration: var(--transition-primary-duration);
      transition-timing-function: var(--transition-primary-timing-function);

      &:hover {
        color: var(--color-primary-blue);
      }
    }

    &__text {
      color: white;
    }
  }

  @media(max-width: 992px) {
    .top-bar {
      &__content {
        padding: 0.625rem;
      }
    }
  }

</style>