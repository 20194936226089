<script setup>
  import { $patch, route } from '@plenny/visitor';
  import { ref, computed } from 'vue';
  import { trans } from '@plenny/translator';

  const signUpEmail = ref(false);

  function onSubmit(data) {
    return $patch(route('api.v1.web.newsletter.update'), data, false, false ).then(() => {
      signUpEmail.value = true;
    });
  }

  const privacyPolicy = computed(() => {
    return trans('Akceptuję <a href=":url" target="_blank">politykę prywatności</a> sklepu.', { url: route('web.privacy_policy.index') });
  });
</script>
<template>
  <div class="newsletter">
    <SegmentHeading larger type="h2">
      {{ $t('Newsletter') }}
    </SegmentHeading>
    <template v-if="!signUpEmail">
      <VisitorForm v-slot="{ submitting }" @submit="onSubmit">
        <p class="newsletter__text">
          {{ $t('Otrzymuj kody rabatowe, aktualności dotyczące promocji oraz informacje o najnowszych produktach.') }}
        </p>
        <div class="newsletter__content">
          <SfFormInput :label="$t('E-mail')" name="email" required />
          <SfButton primary :loading="submitting" type="submit">
            {{ $t('Zapisz się') }}
          </SfButton>
        </div>
        <div class="newsletter__consent">
          <SfFormSwitch name="acceptance_rodo" required>
            <span v-html="privacyPolicy" />
          </SfFormSwitch>
        </div>
      </VisitorForm>
    </template>
    <template v-else>
      <p class="subscribed">{{ $t('Dziękujemy za zapisanie się do naszego newslettera') }}</p>
    </template>
  </div>
</template>
<style lang="scss">
  .newsletter {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    padding: 60px 40px;
    background-color: var(--color-primary-white);

    &__content {
      width: 100%;
      display: flex;
      align-items: flex-end;
      gap: 0;
      margin-top: 3rem;
    }

    &__consent {
      --sf-checkbox-label-font-size: 0.75rem;
      --sf-checkbox-label-color: var(--color-gray);

      a {
        text-decoration: underline;
        transition: color 0.2s ease-out;

        &:hover {
          color: var(--color-primary-blue);
        }
      }
    }

    .subscribed {
      font-weight: 600;
      font-size: 1.25rem;
      text-align: center;
    }
  }

  @media (max-width: 1200px) {
    .newsletter {
      &__content {
        flex-direction: column;
        gap: 0.625rem;
      }
    }
  }

  @media (max-width: 992px) {
    .newsletter {
      padding: 0.625rem;

      &__content {
        margin-top: 0;
        align-items: flex-end;
      }
    }
  }


</style>

