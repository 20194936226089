<template>
  <main class="container">
    <div class="wrapper">
      <SfBreadcrumbs />
    </div>
    <div class="wrapper configurators-container">
      <div class="configurators-wrapper">
        <div class="info">
          <SegmentHeading true type="h1">{{ $t('Konfiguratory') }}</SegmentHeading>
          <p class="info-text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur, cupiditate. Voluptate, asperiores
            illo possimus laboriosam itaque earum similique saepe sunt necessitatibus laborum odit, vitae velit
            vel?
          </p>
        </div>
        <ul class="link-list-wrapper">
          <li>
            <a href="https://hager-konfigurator.pl/epstryk/app/tool-a-index.php" target="_blank" class="link">
              <div class="icon-wrapper">
                <IconChevronRight class="arrow-icon" />
              </div>
              {{ $t('Konfigurator osprzętu elektroinstalacyjnego Berker') }}
            </a>
          </li>
          <li>
            <a href="https://hager-konfigurator.pl/epstryk/app/tool-a2-index.php" target="_blank" class="link">
              <div class="icon-wrapper">
                <IconChevronRight class="arrow-icon" />
              </div>
              {{ $t('Konfigurator osprzętu elektroinstalacyjnego Hager lumina') }}
            </a>
          </li>
          <li>
            <a href="https://hager-konfigurator.pl/epstryk/app/tool-c-index.php" target="_blank" class="link">
              <div class="icon-wrapper">
                <IconChevronRight class="arrow-icon" />
              </div>
              {{ $t('Konfigurator underfloor') }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>

<style scoped lang="scss">
  .configurators-container {
    margin-bottom: 120px;

    .configurators-wrapper {
      background-color: var(--color-white);
      border-radius: 5px;
      padding: 60px;

      .info {
        max-width: 800px;

        .info-text {
          margin-top: 30px;
        }
      }

      .link-list-wrapper {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 40px;
        list-style: none;

        .link {
          display: flex;
          color: var(--color-black);
          font-size: 18px;
          font-weight: 500;
          text-decoration: underline;
          transition: var(--transition-primary);
          line-height: 22px;
          width: fit-content;

          &:hover {
            color: var(--color-primary-blue);
          }

          .icon-wrapper .arrow-icon {
            font-size: 14px;
          }

        }
      }
    }
  }

  @media (max-width: 992px) {
    .configurators-container {
      margin-bottom: 0;

      .configurators-wrapper {
        padding: 30px;
      }
    }
  }
</style>
