<script setup lang="ts">
  import { onMounted, ref, type Ref, onBeforeUpdate, onUpdated, onBeforeUnmount } from 'vue';
  import type { NavButtons } from '$types';
  import Swiper from 'swiper';
  import { Navigation } from 'swiper/modules';

  defineProps({
    perView: { type: Number, required: true, default: 8 },
    categories: { type: Array, required: false, default: [] },
  });

  const htmlElement = ref() as Ref<HTMLElement>;
  const htmlNavigation = ref() as Ref<NavButtons>;
  const swiper = ref() as Ref<Swiper>;

  function initializeSwipers() {
    swiper.value = new Swiper(htmlElement.value, {
      modules: [Navigation],
      slidesPerView: 2.25,
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
        992: {
          slidesPerView: 4,
        },
        1200: {
          slidesPerView: 5,
        },
        1400: {
          slidesPerView: 6,
        },
      },
      navigation: {
        nextEl: htmlNavigation.value.next.htmlElement,
        prevEl: htmlNavigation.value.prev.htmlElement,
      },
    });
  }

  function destroySwipers() {
    swiper.value?.destroy();
  }

  onMounted(() => {
    initializeSwipers();
  });

  onBeforeUpdate(() => {
    destroySwipers();
  });

  onUpdated(() => {
    initializeSwipers();
  });

  onBeforeUnmount(() => {
    destroySwipers();
  });
</script>
<template>
  <div class="items-carousel categories-carousel">
    <div ref="htmlElement" class="swiper items-carousel__container categories-carousel__container">
      <div class="swiper-wrapper">
        <template v-for="category in categories">
          <div class="swiper-slide items-carousel__slide categories-carousel__article">
            <VisitorLink :href="route('web.product.category', { category })" class="categories-carousel__link">
              <MediaPicture :photo="category.photo" sizes="160x120 2x, 80x60" />
              <span>{{ category.title }}</span>
            </VisitorLink>
          </div>
        </template>
      </div>
    </div>
    <SfCarouselButtons ref="htmlNavigation" />
  </div>
</template>
<style scoped lang="scss">
  .categories-carousel {
    &__link {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1.5rem;
      flex-grow: 1;
      align-items: flex-start;
      padding: 1.5rem;
      font-size: 1.25rem;
      text-align: left;
      line-height: 1.5;
      font-weight: var(--font-weight-700);
      background-color: var(--color-primary-white);

      :deep(picture) {
        height: 60px;
        width: 60px;

        img {
          height: 100%;
        }
      }

      span {
        transition-property: color;
        transition-duration: var(--transition-primary-duration);
        transition-timing-function: var(--transition-primary-timing-function);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        word-break: break-word;
      }

      &:hover span {
        color: var(--color-primary-blue);
      }
    }
  }

  @media(max-width: 768px) {
    .categories-carousel__link {
      gap: 1rem;
      padding: 1rem;
      font-size: 0.875rem;

      :deep(picture) {
        height: 2.5rem;
        width: 2.5rem;
      }
    }
  }
</style>

