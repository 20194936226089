<script setup>
  import { ref } from 'vue';
  import { useShared } from '@plenny/visitor';

  const shared = useShared();
  const current = ref(undefined);
  const category = ref();
  const open = defineModel('open', { type: Boolean, required: false, default: false });

  const closeDrawer = function() {
    open.value = false;
  };
</script>

<template>
  <SfDrawer v-model:open="open" class="menu-drawer" left>
    <SfDrawerHeader v-model:open="open" class="menu-drawer__header">
      {{ $t('Menu') }}
    </SfDrawerHeader>
    <div class="menu-drawer__body">
      <div class="menu-drawer__wrapper">
        <ul class="menu-drawer__content">
          <HeaderNavItem v-for="item in shared.menu.menu_top" :depth="0" :item="item" class="menu-drawer__item" @close="closeDrawer" />
        </ul>
      </div>
      <VatSwitcher class="menu-drawer__tax" @click="'click'" />
      <div class="menu-drawer__contact">
        <a v-if="shared.department.phone" :href="`tel:${shared.department?.phone}`" class="menu-drawer__contact-phone">
          <IconPhone class="menu-drawer__contact-phone-icon" />
          <span v-html="shared.department?.phone" />
        </a>
        <a v-if="shared.department?.email" :href="`mailto:${shared.department?.email}`" class="menu-drawer__contact-mail">
          <IconEnvelope class="menu-drawer__contact-mail-icon" />
          <span v-html="shared.department?.email" />
        </a></div>
    </div>
  </SfDrawer>
</template>
<style lang="scss" scoped>
  .menu-drawer {
    --sf-drawer-header-font-size: 1.25rem;
    --sf-drawer-header-gap: 0.75rem;
    --sf-drawer-header-line-height: 2.375rem;
    --sf-drawer-header-padding-y: 0.75rem;
    --sf-drawer-header-padding-x: 1rem;
    --sf-drawer-header-background: white;

    --sf-drawer-padding-x: 0;
    --sf-drawer-padding-y: 0;
    --sf-drawer-gap-y: 0;

    &__header {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    }

    &__wrapper {
      font-weight: var(--font-weight-500);
      padding: 0.75rem 1rem;
    }

    &__body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      gap: 2rem;
    }

    &__tax {
      padding: 1rem;

      :deep(.control__wrapper) {
        width: auto;

        .switch__wrapper {
          flex-direction: row;
        }
      }
    }

    &__contact {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      padding: 2rem 1rem;
      border-top: 1px solid var(--color-primary-light-gray);
    }

    &__contact-phone,
    &__contact-mail {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 1rem;
      line-height: 1.5rem;
      gap: 1rem;

      svg {
        font-size: 1.375rem;
        line-height: 1.375rem;
      }
    }


    &__content {
      display: flex;
      flex-direction: column;
      list-style: none;

      :deep(li.nav-item) {
        a {
          padding: 0.875rem 0;
        }

        .nav-item-arrow {
          display: none;
        }

        .children-wrapper {
          position: static;
          display: flex;
          flex-direction: column;
          border-radius: 0;
          box-shadow: none;
          padding: 0 0 0 1rem;

          .children {
            display: flex;
            flex-direction: column;
          }
        }

        &:hover .children-wrapper {
          border: none;
        }
      }
    }
  }
</style>
