<script setup>
  import { ref, computed } from 'vue';
  import { $post, route, $delete, useShared } from '@plenny/visitor';

  const props = defineProps({
    product: Object,
    sections: Array,
    brand: Object,
    category: Object,
    page: Object,
  });

  const photosParameters = ref({
    slidesPerView: 1,
    navigation: true,
    pagination: false,
    loop: false,
    watchSlidesProgress: true,
  });

  const thumbsParameters = ref({
    slidesPerView: 'auto',
    navigation: true,
    pagination: false,
    loop: false,
    centeredSlidesBounds: true,
    spaceBetween: 10,
  });

  const variant = defineModel('variant', { type: Object, required: false });
  const shared = useShared();

  const isCompared = computed(() => {
    if (variant && shared.value.comparator) {
      return shared.value.comparator.map((c) => c.variant_id).includes(variant.value.id);
    } else {
      return false;
    }
  });

  function toggleComparator() {
    if (isCompared.value) {
      $delete(route('api.v1.web.comparator.destroy', { 'variant_id': variant.value.id }));
    } else {
      $post(route('api.v1.web.comparator.store', { 'variant_id': variant.value.id }));
    }
  }
</script>
<template>
  <div class="container product-details">
    <div class="product-details__gallery-wrapper">
      <SfProductGallery v-bind="{ thumbsParameters, photosParameters, photos: [variant?.photo, ...product.photos]}" />
    </div>
    <div class="product-details__content">
      <div class="product-details__info">
        <ShopProductViewTags :product :variant />
        <header class="product-details__header">
          <h1 class="product-details__title" v-html="variant?.title || product.title" />
          <SfStars :count="product.reviews_count" :rating="product.reviews_average" class="product-details__stars" />
          <div v-if="variant?.description_short || product.description_short" class="product-details__description"
            v-html="variant?.description_short || product.description_short" />
        </header>
        <div class="product-details__compare-wishlist">
          <SfWishlistButton v-slot="{ saved }" :variant="variant.id">
            <SfButton :class="{ saved }" class="favourite" small transparent>
              <template #before>
                <IconHeartFill v-if="saved" />
                <IconHeart v-else />
              </template>
              <template #default>
                <span v-if="saved">{{ $t('Ulubiony') }}</span>
                <span v-else>{{ $t('Dodaj do ulubionych') }}</span>
              </template>
            </SfButton>
          </SfWishlistButton>
          <SfButton v-if="variant" small transparent @click="toggleComparator">
            <template #before>
              <IconArrowDownUp />
            </template>
            <template #default>
              {{ isCompared ? $t('Usuń z porównania') : $t('Porównaj') }}
            </template>
          </SfButton>
        </div>
        <ProductMetaData :brand :category :product :variant />
        <a class="product-details__to-specification" href="#specification">
          {{ $t('Przejdź do pełnej specyfikacji') }}
        </a>
      </div>
      <div class="product-details__actions">
        <ProductMainActions v-model:variant="variant" :brand :product="product" />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .product-details {
    display: grid;
    grid-template-columns: 40% 1fr;
    gap: 2.5rem;
    padding: 2.5rem;
    width: 100%;
    background-color: var(--color-primary-white);

    &__gallery-wrapper {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    &__gallery-wrapper {
      width: 100%;
      gap: 1.5rem;
    }

    &__header {
      display: flex;
      flex-direction: column;
      gap: 0.375rem;
    }

    &__content {
      display: grid;
      grid-template-columns: 5fr 4fr;
    }

    &__title {
      font-size: 1.25rem;
      line-height: 1.5;
      font-weight: var(--font-weight-700);
    }

    &__description {
      font-size: 0.875rem;
    }

    &__compare-wishlist {
      border-top: 1px solid var(--color-primary-light-gray);
      border-bottom: 1px solid var(--color-primary-light-gray);
      padding: 1.5rem 0;
      margin: 1.5rem 0;
    }

    &__info {
      display: flex;
      flex-direction: column;
      padding-right: 1.5rem;
      border-right: 1px solid var(--color-primary-light-gray);
    }

    &__actions {
      padding-left: 1.5rem;
    }

    &__to-specification {
      margin-top: 1rem;
      text-decoration: underline;
      transition-property: color;
      transition-duration: var(--transition-primary-duration);
      transition-timing-function: var(--transition-primary-timing-function);
      cursor: pointer;

      &:hover {
        color: var(--color-primary-blue);
      }
    }
  }

  @media (max-width: 1200px) {
    .product-details {

      gap: 2rem;
      padding: 2rem 1rem;
      width: 98%;

      &__info {
        padding-right: 0;
        border-right: 0;
      }

      &__content {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
      }

      &__actions {
        padding-left: 0;
      }
    }
  }

  @media (max-width: 992px) {
    .product-details-container {
      margin-bottom: 1.5rem;

      .product-details {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        padding: 0.625rem;

        &__title {
          font-size: 1.375rem;
        }

        &__compare-wishlist {
          padding: 1rem 0;
        }

        &__actions {
          --sf-product-price-presentation-catalog-font-size: 0.875rem;
          --sf-product-price-presentation-current-font-size: 2rem;
          --sf-product-price-presentation-gap: 0;

          :deep(.product-actions) {
            gap: 2rem;

            .product-actions__info {
              gap: 1.25rem;
            }

            .price--presentation {
              flex-direction: column;
              align-items: flex-start;
            }
          }
        }
      }
    }
  }
</style>
