<script setup>
  import { ref, computed } from 'vue';
  import { useBreakpoints, useCart } from '$storefront';

  defineOptions({
    inheritAttrs: false,
  });

  const breakpoints = useBreakpoints();
  const show = ref(false);
  const { cart } = useCart();
  const count = computed(() => cart.value?.items?.length || 0);
  const total = computed(() => cart.value?.total_gross || 0);
  const currency = computed(() => cart.value?.currency_code);
  const decimals = computed(() => cart.value?.currency_decimals);
</script>
<template>
  <div class="action-item" @click="show = true" tabindex="0" @keydown.enter="show = true" v-bind="$attrs">
    <div class="icon-wrapper">
      <span v-if="count > 0" class="mini-cart-count" :aria-label="$t('Ilośc produktów w koszyku')">
        {{ count }}
      </span>
      <IconCartHeader class="icon" />
    </div>
    <SfMoney v-if="breakpoints.lg" :code="currency" :decimals="decimals" :value="total" class="cart-value" />
  </div>
  <SfDrawerCart v-model:open="show" />
</template>
<style lang="scss" scoped>
  .action-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 0.375rem;
    font-size: 1.5rem;
    height: 100%;
    color: var(--color-main-black);
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
    transition-property: color;
    transition-duration: var(--transition-primary-duration);
    transition-timing-function: var(--transition-primary-timing-function);

    &:hover {
      color: var(--color-primary-blue);
    }

    &.scroll .cart-value {
      font-size: 0;
      opacity: 0;
    }

    .icon-wrapper {
      position: relative;
      height: 1.5rem;

      .mini-cart-count {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 0.75rem;
        line-height: 1;
        padding: 0.25rem 0.375rem;
        color: var(--color-primary-white);
        transform: translate(50%, -50%);
        background-color: var(--color-primary-blue);
        border-radius: 1.5rem;
      }
    }

    .cart-value {
      font-size: 0.75rem;
      transition-property: font-size, opacity;
      transition-duration: var(--transition-primary-duration);
      transition-timing-function: var(--transition-primary-timing-function);
    }
  }

  @media(max-width: 992px) {
    .action-item {
      flex-direction: row-reverse;
      gap: 1rem;
      font-size: 1.25rem;

      .icon-wrapper {

        .mini-cart-count {
          position: absolute;
          font-size: 0.625rem;
          padding: 0.125rem 0.25rem;
          transform: translate(-50%, -50%);

          right: unset;
          left: 0;
        }
      }

      .cart-value {
        font-size: 0.875rem;
      }
    }
  }
</style>
