<script setup lang="ts">
  import { ref, onMounted, type PropType, type Ref } from 'vue';
  import type { Breadcrumb } from '$types';

  defineProps({
    breadcrumbs: { type: Array as PropType<Breadcrumb[]>, required: true },
  });

  const breadcrumbsContainer = ref() as Ref<HTMLDivElement>;

  onMounted(() => {
    breadcrumbsContainer.value.scrollLeft = breadcrumbsContainer.value.scrollWidth;
  });
</script>
<template>
  <nav aria-label="Breadcrumb" class="breadcrumbs">
    <ol ref="breadcrumbsContainer" class="breadcrumbs__items">
      <li class="breadcrumbs__item breadcrumbs__item--home">
        <VisitorLink :href="route('web.homepage.index')" class="breadcrumbs__link">
          <slot>
            <IconHome />
          </slot>
        </VisitorLink>
      </li>
      <li v-for="(breadcrumb, index) in breadcrumbs" class="breadcrumbs__item">
        <VisitorLink v-if="index !== 0" :href="breadcrumb.url" class="breadcrumbs__link" :aria-current="index === (breadcrumbs.length - 1) ? 'page' : undefined">
          {{ breadcrumb.name }}
        </VisitorLink>
      </li>
    </ol>
  </nav>
</template>
<style lang="scss">

  :root {
    --sf-breadcrumbs-arrow: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' xmlns:v='https://vecta.io/nano'%3e%3cpath d='M121.373 457.373L322.745 256 121.373 54.627a32 32 0 0 1 45.254-45.254l224 224a32 32 0 0 1 0 45.254l-224 224a32 32 0 0 1-45.254-45.254z'/%3e%3c/svg%3e");
  }

  :root {
    --sf-breadcrumbs-gap: 0.625rem;
    --sf-breadcrumbs-background: transparent;
    --sf-breadcrumbs-font-size: 0.875rem;

    --sf-breadcrumbs-color: rgb(90, 90, 90);
    --sf-breadcrumbs-color-active: rgb(90, 90, 90);
    --sf-breadcrumbs-color-first: rgb(90, 90, 90);
    --sf-breadcrumbs-color-hover: rgb(31, 31, 31);
    --sf-breadcrumbs-total-products-color: rgb(12, 12, 12);

    --sf-breadcrumbs-padding-y: 1rem;
    --sf-breadcrumbs-padding-x: 0;
    --sf-breadcrumbs-margin-y: 0;
    --sf-breadcrumbs-margin-x: 0;

    --sf-breadcrumbs-transition-timing-func: ease-in;
    --sf-breadcrumbs-transition-duration: 150ms;
    --sf-breadcrumbs-arrow-size: 0.625rem;
  }

  .breadcrumbs {
    &__items {
      display: flex;
      flex-wrap: wrap;
      gap: var(--sf-breadcrumbs-gap);
      font-size: var(--sf-breadcrumbs-font-size);
      padding: var(--sf-breadcrumbs-padding-y) var(--sf-breadcrumbs-padding-x);
      margin: var(--sf-breadcrumbs-margin-y) var(--sf-breadcrumbs-margin-x);
      background-color: var(--sf-breadcrumbs-background);
      list-style: none;
    }

    &__item--home {
      color: var(--sf-breadcrumbs-color);

      svg {
        font-size: 1rem;
      }
    }

    &__item {

      &:not(:last-child):not(:first-child) {
        &::after {
          content: '';
          display: inline-block;
          height: var(--sf-breadcrumbs-arrow-size);
          width: var(--sf-breadcrumbs-arrow-size);
          margin-left: var(--sf-breadcrumbs-gap);
          background-image: var(--sf-breadcrumbs-arrow);
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      &:last-child .breadcrumbs__link {
        color: var(--sf-breadcrumbs-color-active);

        &:hover {
          color: var(--sf-breadcrumbs-color-hover);
        }
      }
    }

    &__link {
      color: var(--sf-breadcrumbs-color);
      transition: var(--transition-primary);
      transition-property: color;
      transition-timing-function: var(--sf-breadcrumbs-transition-timing-func);
      transition-duration: var(--sf-breadcrumbs-transition-duration);

      &:hover {
        color: var(--sf-breadcrumbs-color-hover);
      }
    }
  }

  @media (max-width: 992px) {
    .breadcrumbs {
      width: 100%;

      &__items {
        flex-wrap: nowrap;
        max-width: 100%;
        --sf-breadcrumbs-padding-y: 0.75rem;
        --sf-breadcrumbs-padding-x: 0.5rem;
        overflow: auto hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &__item {
        white-space: nowrap;
      }
    }
  }
</style>
